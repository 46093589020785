import axios from "axios";

const url_workshopRegister = "api/user/workshopReg";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 20000,
});

export const apiWorkshopRegister = async (data) => {
  try {
    const response = await api.post(`${url_workshopRegister}`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};