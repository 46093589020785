import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { vr } from "../../data/contents";

function Karnival() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="VR Exposure" subtext="Vyuhaa'22" />
      <ContentBox
        desc={vr.desc}
        venue={vr.venue}
        time={vr.time}
        contacts={vr.contact}
        img_src={vr.img_src}
      />
    </div>
  );
}

export default Karnival;