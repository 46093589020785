import React from "react";
import styles from "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Events from "./pages/Events/Events";
import Workshops from "./pages/Workshops/Workshops";
import Ktalk from "./pages/Ktalk/Ktalk";
import Karnival from "./pages/Karnival/Karnival";
import Projects from "./pages/Projects/Projects";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Footer from "./components/Footer/Footer";
import BNB from "./pages/Events/BNB";
import Crunch from "./pages/Events/Crunch";
import Codeverse from "./pages/Events/Codeverse";
import Kryptics from "./pages/Events/Kryptics";
import Memory from "./pages/Events/Memory";
import Quiz from "./pages/Events/Quiz";
import EV from "./pages/Workshops/EV";
import ML from "./pages/Workshops/ML";

function App() {
  return (
    <div className="App">
      <div className="SB">
        <Router>
          <div className="bg">
            <Routes>
              <Route path="/" exact element={<Home />} />

              <Route path="/events" exact element={<Events />} />
              <Route path="/events/kryptics" exact element={<Kryptics />} />
              <Route path="/events/crunch" exact element={<Crunch />} />
              <Route path="/events/quiz" exact element={<Quiz />} />
              <Route path="/events/bnb" exact element={<BNB />} />
              <Route path="/events/memory" exact element={<Memory />} />
              <Route path="/events/codeverse" exact element={<Codeverse />} />

              <Route path="/workshops" exact element={<Workshops />} />
              <Route path="/workshops/ev" exact element={<EV />} />
              <Route path="/workshops/ml" exact element={<ML />} />

              <Route path="/ktalk" exact element={<Ktalk />} />
              <Route path="/vr" exact element={<Karnival />} />
              <Route path="/ideathon" exact element={<Projects />} />

              <Route path={"*"} exact element={<PageNotFound />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
