import axios from "axios";

const url_registerUser = "api/user/register";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 20000,
});

export const apiRegisterUser = async (data) => {
  try {
    const response = await api.post(`${url_registerUser}`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};