export const events = {
	"bnb": {
		desc: [
			"Ahoy Matey! Shiver me timbers, the ship has gone down.",
			"Filled with chests of gold, silver, jewels, and doubloons, everything has been lost. But fret not before you have heard the legend of the land of CEG.",
			"\"Aye, aye, too many looted vessels to counthidden and not to be found. Yet the merry bonds of friendship and the following of hidden clues surface where they shouldn't be found.\"",
		],
		venue: "KP Ground Floor",
		time: "11:00AM - 2:00PM",
		contact: [
			{
				name: "Prasanna",
				phone: "+91 96007 07402",
			},
			{
				name: "Araventh",
				phone: "+91 88255 51857",
			}
		],
		img_src: `${process.env.PUBLIC_URL}/images/events/bnb.jpeg`,
		round: 1,
		teamSize: "2 - 3",
	},
	"codeverse": {
		desc: [
			"The MCU is being attacked by dangerous viruses, and Doctor Doom’s Mydoom-2 is on its way to do so. \"Coders, assemble\" and lift your own coded hammers and shields to save the program.",
			"This Vyuhaa, you can have fun even if you are a DC fan because you can live vicariously through the MCU.",
			"All programmers do arrive to fight in our codeverse of madness, a pair-coding event!",
		],
		venue: "2nd floor lab",
		moreVenue: "IST dept",
		time: "10:30AM - 1:00PM",
		contact: [
			{
				name: "Anusuya",
				phone: "+91 91505 78473",
			},
			{
				name: "Araventh",
				phone: "+91 88255 51857",
			}
		],
		img_src: `${process.env.PUBLIC_URL}/images/events/codeverse.jpeg`,
		round: 2,
		teamSize: "2",
	},
	"crunch": {
		desc: [
			"Good marketing is what determines whether a product or service will be an instant hit or a fatal flop. The core of every business is marketing. But what is at the core of a good marketer?",
			"Do you think you have the niche for the same? If you are looking for an opportunity to showcase your talent and improve your marketing skills, then use this opportunity to participate in this event.",
			"\"Good marketing makes the company look smart. Great marketing makes the customer feel smart!\"",
		],
		venue: "KP 405, 406",
		time: "10:30AM - 12:30PM",
		contact: [
			{
				name: "Prasanna",
				phone: "+91 96007 07402",
			},
			{
				name: "Araventh",
				phone: "+91 88255 51857",
			}
		],
		img_src: `${process.env.PUBLIC_URL}/images/events/crunch.jpeg`,
		round: 2,
		teamSize: "2",
	},
	"kryptics": {
		desc: [
			"Does solving brain twisters and puzzles excite you? Are you passionate about engineering and technology? Then you are in the right place, and this event awaits to quench your thirst to think and solve.",
			"It's a \"connections\" like event where the fastest and smartest brain wins.",
			"Register now and be ready for the imminent brain dares!",
		],
		venue: "KP 401, 402",
		time: "10:30AM - 12:30PM",
		contact: [
			{
				name: "Prasanna",
				phone: "+91 96007 07402",
			},
			{
				name: "Anusuya",
				phone: "+91 91505 78473",
			},
		],
		img_src: `${process.env.PUBLIC_URL}/images/events/kryptics.jpeg`,
		round: 2,
		teamSize: "1 - 2",
	},
	"quiz": {
		desc: [
			"Do you know the answers to random trivias? Do you enjoy being quizzed? Do you want to compete with your friends and against your friends? Then we have the perfect event for you.",
			"CTF brings you an open quiz! Be ready to be quizzed on science, space, books, cartoons, history, and so much more.",
			"With three different rounds, be ready to rise above the competition.",
		],
		venue: "Tag Audi",
		time: "1:00PM - 4:00PM",
		contact: [
			{
				name: "Prasanna",
				phone: "+91 96007 07402",
			},
			{
				name: "Anusuya",
				phone: "+91 91505 78473",
			},
		],
		img_src: `${process.env.PUBLIC_URL}/images/events/quiz.jpeg`,
		round: 3,
		teamSize: "2",
	},
	"memory": {
		desc: [
			"Do you remember playing Track the Ball, Blink, or memory card games as a kid? If so, \"Memory List\" is the ultimate game for you.",
			"Do come and witness this event, where questions will be asked to test your concentration, logical thinking and memorization skills.",
			"It's time to find out if you have a photographic memory or not!",
		],
		venue: "KP 403, 404",
		time: "10:00AM - 12:00PM",
		contact: [
			{
				name: "Prasanna",
				phone: "+91 96007 07402",
			},
			{
				name: "Anusuya",
				phone: "+91 91505 78473",
			},
		],
		img_src: `${process.env.PUBLIC_URL}/images/events/memory.jpeg`,
		round: 2,
		teamSize: "Individual",
	},
}

export const workshops = {
	"ev": {
		desc: [
			"Making it right in time for 8:30 classes by waking up at 8:00 is impossible without EV in our campus, especially for the non-circuit branches.",
			"If you are someone who has a keen interest in electric vehicles, well, hop on the bandwagon.",
			"Introducing \"Electric Vehicle Fundamentals Workshop\" in Vyuhaa'22, an interactive half-day workshop which is absolutely free!",
			"Come, let's learn about the future of the automobile industry.",
		],
		venue: "Hall of Guines",
		moreVenue: "Manufacturing dept",
		time: "1:00PM - 4:30PM",
		speaker: {
			name: "Mr. S. Anand",
			designation: "EV Course Instructor at CADD Centre",
		},
		contact: [
			{
				name: "Hemanth SA",
				phone: "+91 73391 73505",
			},
			{
				name: "Karen Jerusha",
				phone: "+91 96772 74647",
			},
		],
		img_src: `${process.env.PUBLIC_URL}/images/ev.png`,
	},
	"ml": {
		desc: [
			"From your Netflix suggestions to gaining business intelligence, ML is now entering every field.",
			"Humanity has now entered a new age of evolution with machine learning.",
			"Thus, CTF invites you to join our workshop on Machine Learning to help you get a clear understanding on the topic.",
			"Grab the seats soon as we have only limited seats. Get ready folks!",
		],
		venue: "Turing Hall",
		moreVenue: "CSE dept",
		time: "9:00AM - 12:30PM",
		speaker: {
			name: "Srinath Sureshkumar",
			designation: "Software Engineer at Arcesium",
		},
		contact: [
			{
				name: "Sujith",
				phone: "+91 80569 08993",
			},
			{
				name: "Priya",
				phone: "+91 83008 02817",
			},
			{
				name: "Athish",
				phone: "+91 86104 62210",
			},
		],
		img_src: `${process.env.PUBLIC_URL}/images/ml.png`,
	}
}

export const ktalk = {
	desc: [
		"K!Talks is a showcase of speakers presenting on interesting tech and non-tech ideas and motivational speeches that will enlighten students with new ideas and inspire the gathered community.",
		"Topic: Technical challenges and experiences in making of KALAM-SAT and how students can groom themselves to build their own satellites.",
		"About the speaker: Srimathy Kesan is the founder and CEO of Space Kidz India, and is also a WEF Educational, cultural and Travel ambassador for kids."
	],
	venue: "Vivek Audi",
	time: "5:00PM",
	speaker: {
		name: "Dr. Srimathy Kesan",
		designation: "Founder, CEO - Space Kidz India",
	},
	contact: [
		{
			name: "Hemananthini",
			phone: "+91 90259 67826",
		}
	],
	img_src: `${process.env.PUBLIC_URL}/images/ktalk.jpg`,
	yt_src: "https://www.youtube.com/embed/T1uPL2iKVIE?list=PLG6EkHidBHF49t6J-Orr_POPxds6iSnUR",
}

export const ideathon = {
	desc: [
		"Ideathon is a team event which is conducted to provide students with opportunities to work on real time projects.",
		"Participants of the event will be provided with multi-disciplinary industrial problem statements which is obtained from industries and they will be asked to provide feasible solutions for it.",
		"The best and feasible solution which is pitched will win and the solution which wins will be developed as a prototype by CTF Projects in collaboration with the industry which gave the problem statement.",
		"If interested, the winners of the event can work on building a prototype for the solution they pitched thus providing them with lots of industrial exposure.",
	],
	venue: "KP 408",
	time: "10:30AM",
	contact: [
		{
			name: "Sandhiya R",
			phone: "+91 90259 59808",
		},
		{
			name: "Poovarasan T",
			phone: "+91 96551 50050",
		},
	],
	img_src: `${process.env.PUBLIC_URL}/images/ideathon.png`,
}

export const vr = {
	desc: [
		"Virtual reality is the creation and application of a simulated world.",
		"This Vyuhaa'22 we have planned to let you immerse in the world of virtual reality! Get insights and information on the same.",
		"Join us on our trip to a completely different realm!",
	],
	venue: "KP 407",
	time: "10:30AM - 4:30PM",
	contact: [
		{
			name: "Deepak",
			phone: "+91 79043 91142",
		},
		{
			name: "Harini",
			phone: "+91 91505 11278",
		}
	],
	img_src: `${process.env.PUBLIC_URL}/images/vr.jpg`,
}