import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { workshops } from "../../data/contents";

function ML() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="Machine Learning" subtext="Vyuhaa'22" />
      <ContentBox
        desc={workshops.ml.desc}
        venue={workshops.ml.venue}
        moreVenue={workshops.ml.moreVenue}
        time={workshops.ml.time}
        speaker={workshops.ml.speaker}
        contacts={workshops.ml.contact}
        img_src={workshops.ml.img_src}
      />
    </div>
  );
}

export default ML;