import React, { useEffect } from "react";
import styles from "./Home.module.css";
// import logo from "../../../public/images/logo_main.png";
import { Link } from "react-router-dom";

import Registration from "../../components/Registration/Registration";
import ScrollArrow from "../../components/ScrollArrow/ScrollArrow";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${styles.display}`}>
      {/* Logo and Pages navigation Layout - Desktop and Tablet view */}
      <div className={styles.landingPC}>
        <div className={styles.title}>VYUHAA'22</div>
        <div className={styles.date}>November 19</div>
        <div className={styles.grid}>
          <div className={styles.gridContainer}>
            <div className={`${styles.gridItem} ${styles.gi_Left}`}>
              <Link to="/ktalk">
                <p>K!talk</p>
              </Link>
            </div>
            <div className={`${styles.gridItem} ${styles.gi_Left}`}>
              <Link to="/ideathon">
                <p>Ideathon</p>
              </Link>
            </div>
            <div className={`${styles.gridItem} ${styles.gi_Left}`}>
              <Link to="/vr">
                <p>VR Expo</p>
              </Link>
            </div>
          </div>

          <div className={`${styles.gridItem} ${styles.logoImg}`}>
            <a href="#regContainer">
              <img src={`${process.env.PUBLIC_URL}/images/logo_main.png`} alt={"Logo"} />
            </a>
          </div>

          <div className={styles.gridContainer}>
            <div className={`${styles.gridItem} ${styles.gi_Right}`}>
              <Link to="/events">
                <p>Events</p>
              </Link>
            </div>
            <div className={`${styles.gridItem} ${styles.gi_Right}`}>
              <Link to="/workshops">
                <p>Workshops</p>
              </Link>
            </div>
            <div className={`${styles.gridItem} ${styles.gi_Right}`}>
              <a
                href="https://cegtechforum.in/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Contact</p>
              </a>
            </div>
          </div>
        </div>
        <a href="#regContainer">
          <ScrollArrow />
        </a>

      </div>

      {/* Logo and Pages navigation Layout - Mobile view */}
      <div className={styles.landingMOBILE}>
        <div className={styles.logo_container}>
          <div className={styles.heading}>VYUHAA'22</div>
          <div className={styles.date}>November 19</div>
          <div className={styles.img_container}>
            <a href="#options">
              <img src={`${process.env.PUBLIC_URL}/images/logo_main.png`} alt={"Logo"} />
            </a>
          </div>
          <a href="#regContainer">
            <ScrollArrow />
          </a>
        </div>
        <div className={styles.link_container} id="options">
          <div className={styles.links}>
            <Link to="/events">
              <p>Events</p>
            </Link>
          </div>
          <div className={styles.links}>
            <Link to="/workshops">
              <p>Workshops</p>
            </Link>
          </div>
          <div className={styles.links}>
            <Link to="/ktalk">
              <p>K!talk</p>
            </Link>
          </div>
          <div className={styles.links}>
            <Link to="/ideathon">
              <p>Ideathon</p>
            </Link>
          </div>
          <div className={styles.links}>
            <Link to="/vr">
              <p>VR Expo</p>
            </Link>
          </div>
          <div className={styles.links}>
            <a
              href="https://cegtechforum.in/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Contact</p>
            </a>
          </div>
        </div>
      </div>

      {/* General Registration - Generates Vyuhaa ID*/}
      <div id="regContainer" className={styles.reg_container}>
        <Registration />
      </div>
    </div>
  );
}

export default Home;
