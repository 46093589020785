import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import {events} from "../../data/contents";

function Memory() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
      <div>
      <Heading text="Memory List" subtext="Vyuhaa'22" />
      <ContentBox
        desc={events.memory.desc}
        venue={events.memory.venue}
        time={events.memory.time}
        contacts={events.memory.contact}
        rounds={events.memory.round}
        teamSize={events.memory.teamSize}
        img_src={events.memory.img_src}
        
      />
    </div>
    );
}

export default Memory;