export const workshopsList=[
    {
        id: 1,
        name: 'Electric Vehicle fundamentals',
        cname: 'ev'
    },
    {
        id: 2,
        name: 'Machine Learning',
        cname: 'ml'
    },
];