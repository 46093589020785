import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { events } from "../../data/contents";

function Kryptics() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="Kryptics" subtext="Vyuhaa'22" />
      <ContentBox
        desc={events.kryptics.desc}
        venue={events.kryptics.venue}
        time={events.kryptics.time}
        contacts={events.kryptics.contact}
        rounds={events.kryptics.round}
        teamSize={events.kryptics.teamSize}
        img_src={events.kryptics.img_src}
      />
    </div>
  );
}

export default Kryptics;