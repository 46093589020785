import React, { useEffect } from "react";
import styles from "./Events.module.css";
import Heading from "../../components/Heading/Heading";
import { eventsList } from "../../data/EventsList";
import { useNavigate } from "react-router-dom";

function Events() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.display}>
      <Heading text="Events" subtext="Vyuhaa'22" />
      <div className={styles.contentContainer}>
        {eventsList.map((event, index) => {
          return (
            <div
              className={(event.fresher === 'yes' || event.fresher === 'yess') ? styles.content_exc : styles.content}
              key={index}
              onClick={() => navigate(event.cname)}>
              {event.name}
              {(event.fresher === 'yes') && <span>Fresher Exclusive</span>}
              {(event.fresher === 'yess') && <span>Fresher and</span>}
              {(event.fresher === 'yess') && <span>2nd year Exclusive</span>}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Events;
