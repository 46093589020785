import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { ktalk } from "../../data/contents";

function Ktalk() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="K!Talk" subtext="Vyuhaa'22" />
      <ContentBox
        desc={ktalk.desc}
        venue={ktalk.venue}
        time={ktalk.time}
        speaker={ktalk.speaker}
        contacts={ktalk.contact}
        yt_src={ktalk.yt_src}
      />
    </div>
  );
}

export default Ktalk;