import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { events } from "../../data/contents";

function Crunch() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="Tech Crunch" subtext="Vyuhaa'22" />
      <ContentBox
        desc={events.crunch.desc}
        venue={events.crunch.venue}
        time={events.crunch.time}
        contacts={events.crunch.contact}
        rounds={events.crunch.round}
        teamSize={events.crunch.teamSize}
        img_src={events.crunch.img_src}
      />
    </div>
  );
}

export default Crunch;