import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { events } from "../../data/contents";

function Codeverse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="Codeverse of Madness" subtext="Vyuhaa'22" />
      <ContentBox
        desc={events.codeverse.desc}
        venue={events.codeverse.venue}
        moreVenue={events.codeverse.moreVenue}
        time={events.codeverse.time}
        contacts={events.codeverse.contact}
        rounds={events.codeverse.round}
        teamSize={events.codeverse.teamSize}
        img_src={events.codeverse.img_src}
      />
    </div>
  );
}

export default Codeverse;