import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { workshops } from "../../data/contents";

function EV() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="Electric Vehicles" subtext="Vyuhaa'22" />
      <ContentBox
        desc={workshops.ev.desc}
        venue={workshops.ev.venue}
        moreVenue={workshops.ev.moreVenue}
        time={workshops.ev.time}
        speaker={workshops.ev.speaker}
        contacts={workshops.ev.contact}
        img_src={workshops.ev.img_src}
      />
    </div>
  );
}

export default EV;