export const eventsList = [
    {
        id: 1,
        name: 'Kryptics',
        cname: 'kryptics',
        fresher: 'yess',
    },
    {
        id: 2,
        name: 'Open Quiz',
        cname: 'quiz',
        fresher: 'no',
    },
    {
        id: 3,
        name: 'Memory List',
        cname: 'memory',
        fresher: 'yes',
    },
    {
        id: 4,
        name: 'Tech Crunch',
        cname: 'crunch',
        fresher: 'no',
    },
    {
        id: 5,
        name: 'Brains and Brawn',
        cname: 'bnb',
        fresher: 'yes',
    },
    {
        id: 6,
        name: 'Codeverse of Madness',
        cname: 'codeverse',
        fresher: 'yess',
    },
];