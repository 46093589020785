import React, { useEffect } from "react";
import ContentBox from "../../components/ContentBox/ContentBox";
import Heading from "../../components/Heading/Heading";
import { ideathon } from "../../data/contents";

function Ideathon() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Heading text="Ideathon" subtext="Vyuhaa'22" />
      <ContentBox
        desc={ideathon.desc}
        venue={ideathon.venue}
        time={ideathon.time}
        contacts={ideathon.contact}
        img_src={ideathon.img_src}
      />
    </div>
  );
}

export default Ideathon;